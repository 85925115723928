import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";
import "./../styles.scss";

import bgLeft from "./../../../img/bg-left.svg";
import bgRight from "./../../../img/bg-right.svg";
import { useEffect } from "react";

const CareerDeveloper = () => {
  useEffect(() => {
    // Set canonical for the current page
    const canonicalLink = document.querySelector('link[rel="canonical"]');
    if (canonicalLink) {
      canonicalLink.setAttribute(
        "href",
        "https://talentbankai.com/services/career-developer"
      );
    } else {
      const newLink = document.createElement("link");
      newLink.rel = "canonical";
      newLink.href = "https://talentbankai.com/services/career-developer";
      document.head.appendChild(newLink);
    }
    // Restore canonical and title on page leave
    return () => {
      if (canonicalLink) {
        canonicalLink.setAttribute("href", "https://talentbankai.com");
      } else {
        const defaultLink = document.querySelector('link[rel="canonical"]');
        if (defaultLink) {
          defaultLink.setAttribute("href", "https://talentbankai.com");
        } else {
          const newLink = document.createElement("link");
          newLink.rel = "canonical";
          newLink.href = "https://talentbankai.com";
          document.head.appendChild(newLink);
        }
      }
      document.title =
        "Tech Recruiters | IT Recruitment | Hiring Engineers - TalentbankAI";
    };
  }, []);

  return (
    <div className="services-page pt-28 pb-24 lg:pt-48">
      <Helmet>
        {/* Document Title */}
        <title>
          Software Developer Career Path | Software, Web & Full-Stack |
          TalentBank AI
        </title>

        {/* Meta Description */}
        <meta
          name="description"
          content="Explore personalized web developer career with TalentBank AI. Gain insights into skills, roadmaps, and opportunities in your developer career. Start your journey today!"
        />

        {/* Canonical Link */}
        <link
          rel="canonical"
          href="https://talentbankai.com/services/career-developer"
        />

        {/* Open Graph Meta Tags */}
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://talentbankai.com/services/career-developer"
        />
        <meta
          property="og:title"
          content="Software Developer Career Path | Software, Web & Full-Stack | TalentBank AI"
        />
        <meta
          property="og:description"
          content="Explore personalized web developer career with TalentBank AI. Gain insights into skills, roadmaps, and opportunities in your developer career. Start your journey today!"
        />
        <meta
          property="og:image"
          content="https://talentbankai.com/talentbank-og.png"
        />

        {/* Twitter Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:url"
          content="https://talentbankai.com/services/career-developer"
        />
        <meta
          name="twitter:title"
          content="Software Developer Career Path | Software, Web & Full-Stack | TalentBank AI"
        />
        <meta
          name="twitter:description"
          content="Explore personalized web developer career with TalentBank AI. Gain insights into skills, roadmaps, and opportunities in your developer career. Start your journey today!"
        />
        <meta
          name="twitter:image"
          content="https://talentbankai.com/talentbank-og.png"
        />
      </Helmet>
      <div className="container max-w-[1140px] mx-auto px-4 relative z-10">
        <h1 className="font-extrabold mb-7 md:mb-10 text-4xl md:text-5xl leading-tight md:leading-tight max-w-[950px]">
          Developer Career Path with TalentBank AI: Empowering Developers
        </h1>
        <p>
          If you're seeking to define or redefine your career as a developer,
          TalentBank AI’s is the perfect solution for building a successful
          pathway in the tech industry. Designed for professionals in roles such
          as software developers, web developers, and full-stack developers,
          this platform leverages AI to offer tailored career guidance, skill
          gap analysis, and resources to propel your growth
        </p>

        <h2>Explore Your Developer Career Path</h2>
        <p>
          Whether you're a seasoned professional or just starting your journey,{" "}
          <NavLink
            to={"/"}
            className="text-blue font-normal underline underline-offset-2"
          >
            TalentBank AI
          </NavLink>{" "}
          helps clarify your next steps. From mapping the{" "}
          <span>web developer career path </span> to advancing in the{" "}
          <span> full-stack developer career</span>, our platform provides:
        </p>
        <ul>
          <li className="marked-item">
            <span className="list-title">Comprehensive Career Planning: </span>
            Chart clear routes for advancing your role in software, web, or
            full-stack development.
          </li>
          <li className="marked-item">
            <span className="list-title">Skills Visibility: </span>Understand
            the competencies required for future roles and bridge the gaps to
            achieve your goals.
          </li>
          <li className="marked-item">
            <span className="list-title">Role-Specific Insights: </span>Tailored
            recommendations for growing your expertise as a software, web, or
            full-stack developer.
          </li>
        </ul>

        <h2>Personalized Guidance for Developers</h2>
        <p>Take charge of your professional growth with:</p>
        <ul>
          <li className="marked-item">
            <span className="list-title">Skill Enhancement Plans: </span>Receive
            targeted insights to enhance your coding, design, and
            problem-solving skills.
          </li>
          <li className="marked-item">
            <span className="list-title">Career Roadmaps: </span>Define a
            step-by-step plan to move from entry-level to senior roles,
            including <span>software developer career path </span> and{" "}
            <span>web developer career description </span> options.
          </li>
          <li className="marked-item">
            <span className="list-title">Learning Resources: </span>Access
            personalized training modules, mentorship programs, and project
            opportunities relevant to your current role and aspirations.
          </li>
        </ul>

        <h2>Web and Software Developer Career Descriptions</h2>
        <p>TalentBank AI empowers developers by helping them:</p>
        <ul>
          <li className="marked-item">
            Understand the nuances of their chosen career path, whether it's{" "}
            <span>web development, software engineering </span>, or{" "}
            <span>full-stack development</span>.
          </li>
          <li className="marked-item">
            Gain real-time feedback on how their current skills match industry
            standards.
          </li>
          <li className="marked-item">
            Discover <span>web developer career descriptions </span> and
            expectations to set clear professional goals.
          </li>
        </ul>

        <h2>Why Choose TalentBank AI for Navigating Your Developer Career?</h2>
        <p>
          The tech industry evolves rapidly, and developers must stay ahead of
          the curve. TalentBank AI equips you with the tools to:
        </p>
        <ul>
          <li className="marked-item">
            <span className="list-title">Plan Your Future: </span>Explore
            long-term career opportunities, such as transitioning into
            leadership or specializing in niche areas.
          </li>
          <li className="marked-item">
            <span className="list-title">Optimize Your Profile: </span>Use
            AI-driven analytics to enhance your professional profile, showcasing
            your expertise to prospective employers.
          </li>
          <li className="marked-item">
            <span className="list-title">Build a Resilient Career: </span>Gain
            insights into market trends to future-proof your skills and stay
            relevant in an ever-changing landscape.
          </li>
        </ul>

        <h2>Key Developer Roles Covered</h2>
        <p>
          TalentBank AI supports professionals across various developer roles:
        </p>
        <ul>
          <li className="marked-item">
            <span className="list-title">Software Developer Career Path: </span>
            Plan your journey from junior developer to senior positions or
            specialized roles in software engineering.
          </li>
          <li className="marked-item">
            <span className="list-title">Web Developer Career Path: </span>
            Understand the roadmap for mastering front-end, back-end, and
            full-stack web development.
          </li>
          <li className="marked-item">
            <span className="list-title">Full-Stack Developer Career: </span>
            Build expertise across multiple technologies and frameworks to
            become a versatile tech professional.
          </li>
        </ul>

        <h2>Your Partner in Career Development</h2>
        <p>
          TalentBank AI is your ultimate career companion. Whether you're
          interested in{" "}
          <span>
            web developer career opportunities, software developer career growth{" "}
          </span>
          , or understanding the intricacies of a <span>developer career </span>
          , our platform is here to guide you every step of the way.
        </p>
        <p>
          Take the next step in your <span>developer career </span>today with
          TalentBank AI and unlock your full potential in software and web
          development.
        </p>
      </div>

      <img
        src={bgLeft}
        className="fixed z-[-1] left-0 top-[150px] hidden xl:block"
        width={428}
        height={236}
        alt="bg"
      />
      <img
        src={bgRight}
        className="fixed z-[-1] right-0 top-[-150px] hidden xl:block"
        width={428}
        height={236}
        alt="bg"
      />
    </div>
  );
};

export default CareerDeveloper;
