import React from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

import logo from "./../../img/logo.svg";
import logoDark from "./../../img/logo-dark.svg";
import ball from "./../../img/ball.svg";
import DBlogo from "./../../img/DB-logo.svg";
import DBlogoDark from "./../../img/DB-logo-dark.svg";

const Footer = () => {
  const isDarkMode = useSelector((state) => state.theme.isDarkMode);

  return (
    <footer className="relative flex items-center py-6 md:py-8 bg-white dark:bg-themeBlack border-t border-t-lightBlue">
      <div className="container max-w-[1332px] mx-auto px-4">
        <div className="wrapper order-1 md:order-1 flex flex-col lg:flex-row lg:mb-5 justify-between items-center">
          <NavLink
            to={"/"}
            className="flex justify-center items-center mb-6 lg:mb-0"
          >
            <div className="flex object-contain">
              <img
                src={isDarkMode ? logoDark : logo}
                className="logo h-[36px] md:h-[46px] w-auto"
                width={"110"}
                height={"52"}
                alt="logo"
              />
            </div>
          </NavLink>

          <div className="nav order-3 lg:order-2 flex flex-wrap items-center gap-x-10 lg:gap-3 lg:flex-nowrap mb-4 lg:mb-0">
            <NavLink
              className="relative flex items-center py-3 lg:px-3 md:text-sm lg:text-base whitespace-nowrap hover:text-blue transition-colors before:content-[''] before:bg-blue before:w-[5px] before:h-[5px] before:rounded-full before:mr-2"
              to={"/#features"}
            >
              Features
            </NavLink>
            <NavLink
              className="relative flex items-center py-3 lg:px-3 md:text-sm lg:text-base whitespace-nowrap hover:text-blue transition-colors before:content-[''] before:bg-blue before:w-[5px] before:h-[5px] before:rounded-full before:mr-2"
              to={"/services/career-developer"}
            >
              Our services
            </NavLink>
            <NavLink
              className="relative flex items-center py-3 lg:px-3 md:text-sm lg:text-base whitespace-nowrap hover:text-blue transition-colors before:content-[''] before:bg-blue before:w-[5px] before:h-[5px] before:rounded-full before:mr-2"
              to={"/#pricing"}
            >
              Pricing
            </NavLink>
            <NavLink
              className="relative flex items-center py-3 lg:px-3 md:text-sm lg:text-base whitespace-nowrap hover:text-blue transition-colors before:content-[''] before:bg-blue before:w-[5px] before:h-[5px] before:rounded-full before:mr-2"
              to={"/#contact"}
            >
              Contact Us
            </NavLink>
            <a
              href="https://blog.talentbankai.com/"
              className="relative flex items-center py-3 lg:px-3 md:text-sm lg:text-base whitespace-nowrap hover:text-blue transition-colors before:content-[''] before:bg-blue before:w-[5px] before:h-[5px] before:rounded-full before:mr-2"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="talentbankai blog"
            >
              Blog
            </a>
          </div>

          <NavLink
            to={"/survey/1"}
            className="group order-2 lg:order-3 hover:bg-darkBlue transition-colors h-[54px] mb-4 lg:mb-0 px-2 w-full sm:w-44 md:w-40 lg:w-44 flex justify-center items-center rounded-xl text-base font-medium text-white bg-blue"
          >
            <p className="me-2 font-Author text-lg whitespace-nowrap">
              Get Started
            </p>
            <img
              className="group-hover:rotate-90 group-hover:translate-x-2 duration-500 h-[22px] w-[22px]"
              src={ball}
              width={22}
              height={22}
              alt="ball"
            />
          </NavLink>
        </div>

        <div className="order-4 flex flex-wrap items-center gap-x-6 gap-y-1 sm:justify-center lg:gap-x-4 xl:gap-x-14">
          <a
            href="https://devbrother.com/"
            className="lg:order-2 flex items-center justify-center mb-6 w-full lg:w-auto lg:mb-0 hover:text-blue transition-colors"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="devbrother company main site"
          >
            <img
              src={isDarkMode ? DBlogoDark : DBlogo}
              className="mr-5"
              width={64}
              height={37}
              alt="devbrother logo"
            />
            <p className="w-32 text-xs">
              Project by<span className="font-semibold"> DevBrother </span>team
            </p>
          </a>
          <NavLink
            className="relative lg:order-2 flex items-center py-3 lg:px-3 text-xs whitespace-nowrap hover:text-blue transition-colors before:content-[''] before:bg-black dark:before:bg-white before:w-[5px] before:h-[5px] before:rounded-full before:mr-2"
            to={"/terms-of-use"}
          >
            Terms Of Use
          </NavLink>
          <NavLink
            className="relative lg:order-3 flex items-center py-3 lg:px-3 text-xs whitespace-nowrap hover:text-blue transition-colors before:content-[''] before:bg-black dark:before:bg-white before:w-[5px] before:h-[5px] before:rounded-full before:mr-2"
            to={"/privacy-policy"}
          >
            Privacy Policy
          </NavLink>
          <NavLink
            className="relative lg:order-3 flex items-center py-3 lg:px-3 text-xs whitespace-nowrap hover:text-blue transition-colors before:content-[''] before:bg-black dark:before:bg-white before:w-[5px] before:h-[5px] before:rounded-full before:mr-2"
            to={"/cookies-policy"}
          >
            Cookies Policy
          </NavLink>
          <a
            href="mailto:yuriy@devbrother.com"
            className="w-full sm:w-auto lg:ml-auto md:order-4 py-3 text-xs font-bold hover:text-blue transition-colors"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="devbrother company CEO email"
          >
            <span className="font-normal">Email:</span> Yuriy@Devbrother.Com
          </a>
          <p className="w-full sm:w-auto py-3 text-xs font-normal lg:order-1 lg:mr-auto">
            Copyright<span className="font-bold"> Talent bank </span> ©2023
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
